import { Box, Container, Heading, SimpleGrid, Stack, Text, } from "@chakra-ui/react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/Base/Layout"
import React from "react"
import { SEO } from "../components/Base/Seo"

const PrivacyPolicy: React.FC<PPProps> = ({ data }) => {
    
    const {
        wpPage: {
            terms_and_conditions: {
                termsText
            }
        }
    } = data
    
    return (
        <Layout>
            <Box as="section" bg="white">
                <Container py={{ base: '16', md: '24' }} maxW="7xl">
                    <Stack spacing={{ base: '12', md: '16' }}>
                        <Stack spacing={{ base: '4', md: '5' }} >
                        <Stack spacing="3">
                            <Heading color="gray.800" fontSize="4xl">
                                Privacy Policy
                            </Heading>
                        </Stack>
                        <Text color="gray.500" fontSize="20px" dangerouslySetInnerHTML={{ __html: termsText }}></Text>
                    </Stack>
              
                    </Stack>
                </Container>
            </Box>
        </Layout>
    )
}

type PPProps = PageProps & {
    data: {
        wpPage: {
            terms_and_conditions: {
                termsText: string
            }
        }
    }
}

export const query = graphql`
 {
    wpPage(slug: {eq: "terms-and-conditions"}) {
        terms_and_conditions {
            termsText
        }
    }
  }
`


export default PrivacyPolicy


export const Head = () => (
  <SEO title="AceMedical Wholesale | Terms&Conditions" description="Here, at Ace Medical Wholesale (“AceMedicalWholesale.com”), we are committed to preserving the privacy interests of our customers. We have established this Privacy Policy to set out the principles that we follow in handling customer personal information." />
)